export const SubmitForm = () => {
    const submitButtons = document.querySelectorAll('[data-submit]');
    if(!submitButtons) { return; }

    // submitButtons.forEach((button) => {
    //     button.addEventListener("click", (e) => {
    //         let formQuery = e.target.getAttribute('data-submit');
    //         let formElement = document.querySelector(`${formQuery}`);

    //     })
    // })
}