export const HeaderHeight = () => {
  const headerTop = document.getElementById('header-top');
  //const channel = document.getElementById('channels');
  const headerMain = document.getElementById('site-header');

  if (!headerMain) return;

  const headerTopHeight = headerTop ? headerTop.offsetHeight : 0;
  //const channelHeight = channel ? channel.offsetHeight : 0; 
  const headerMainHeight = headerMain.offsetHeight;

  if (headerMain.classList.contains('stay-scrolled')) return;

  const height = headerMain.classList.contains('scrolled') ? headerMainHeight : headerMainHeight + headerTopHeight;

  document.querySelector('body').setAttribute('style', `--header-height: ${height}px;`);
};
