export const HeaderHover = () => {
  const headerMenuTab = document.querySelectorAll('[data-header-menu-tab]');
  headerMenuTab.forEach((tab) => {
    tab.addEventListener('mouseenter', e => {
      e.target.classList.add('active');
    });
    tab.addEventListener('mouseleave', e => {
      e.target.classList.remove('active');
    });
  })
}
