import { Throttle } from "../_utilities/_throttle";
import { HeaderHeight } from "./_headerHeight";

export const Header = () => {
  const headerMain = document.querySelector('.main-header');
  const header = document.getElementById('site-header');
  const mobileMenu = document.getElementById('mobile-menu');
  const mobileMenuMainInner = document.getElementById('mobile-menu-main-inner');
  const subMenuButtons = document.querySelectorAll('[data-toggle-sub-menu]');
  const toggleMainMenu = document.querySelector('.mobile-button-main');
  const mobileSearchTrigger = document.querySelector('.mobile-search-trigger');
  const mobileSearchBar = document.querySelector('.mobile-header__search');
  const mainElement = document.querySelector('main');
  const headerSearchElement = document.querySelector('.header-search');

  // Hide channels for non AU users on the AU domain
  let storedCountryCode = sessionStorage.getItem('countryCode');

  if (storedCountryCode &&  !['AU', 'NZ'].includes(storedCountryCode)) {
    if (globalThis.Shopify.country === 'AU') {
      document.getElementById('outlet').style.display = 'none';
    }
    document.getElementById('outlet-menu-item').style.display = 'none';
  } else {
    fetch('https://ipinfo.io?token=ec262f0cd7eb03')
      .then(response => response.json())
      .then(data => {
        sessionStorage.setItem('countryCode', data.country);
        if (!['AU', 'NZ'].includes(data.country)) {
          if (globalThis.Shopify.country === 'AU') {
            document.getElementById('outlet').style.display = 'none';
          }
          document.getElementById('outlet-menu-item').style.display = 'none';
        }
      }) 
      .catch(error => console.error('Error:', error));
  }

  // Loop through search term placholders
  const searchPlaceholders = [
    'Search Maternity',
    'Search Fayreform',
    'Search Sleepwear',
    'Search Strapless'
  ];
  const $searchInputs = document.querySelectorAll('.search-input');
  let index = 0;

  if ($searchInputs.length) {
    $searchInputs.forEach(($searchInput) => {
      setInterval(function () {
        $searchInput.placeholder = searchPlaceholders[index];
        index = (index + 1) % searchPlaceholders.length;
      }, 4000); // Change placeholder every 4 seconds
    });
  }

  if (mobileMenu && subMenuButtons) {
    subMenuButtons.forEach((menuButton) => {
      menuButton.addEventListener("click", (e) => {
        let subMenuElement = document.querySelector(e.target.getAttribute('data-toggle-sub-menu'));
        mobileMenu.classList.add('sub-menu-open');
        mobileMenu.setAttribute('style', `--js-height: 20px;`)
        subMenuElement.classList.toggle('active');
        if (subMenuElement.classList.contains('active')) {
          mobileMenuMainInner.classList.add('no-scroll');
        } else {
          mobileMenuMainInner.classList.remove('no-scroll');
        }
      })
    })
    toggleMainMenu.addEventListener('click', () => {
      // Reset menu if the main menu button is hit.
      if (mobileMenu.classList.contains('sub-menu-open')) {
        document.body.classList.remove('no-scroll');
        mobileMenu.classList.remove('sub-menu-open');
        document.querySelectorAll('.mobile-sub-menu.active').forEach((mobileMenu) => {
          mobileMenu.classList.remove('active');
        })
      }
    })
  }

  // If Hero section is the first section, make header fixed and transparent
  if (header.dataset.headerMode == 'transparent' && mainElement.firstElementChild.classList.contains('full-width-hero')) {
    headerMain.classList.add('header--transparent');
    header.classList.add('header--transparent');
    const heroSectionMeta = mainElement.querySelector('.hero-slide__meta');
    if (heroSectionMeta.classList.contains('flex-to-left')) {
      heroSectionMeta.classList.add('content--top');
    }
    if (headerSearchElement) {
      headerSearchElement.addEventListener('click', () => {
        if (headerMain.classList.contains('header--transparent')) {
          headerMain.classList.remove('header--transparent');
          header.classList.remove('header--transparent');
        } else {
          headerMain.classList.add('header--transparent');
          header.classList.add('header--transparent');
        }
      });
    }
    if (mobileMenu && mobileSearchTrigger && mobileSearchBar)  {
      mobileSearchTrigger.addEventListener('click', () => {
        if (headerMain.classList.contains('header--transparent')) {
          headerMain.classList.remove('header--transparent');
          header.classList.remove('header--transparent');
        } else {
          headerMain.classList.add('header--transparent');
          header.classList.add('header--transparent');
        }
      })
    }
  }

  if (headerSearchElement) {
    headerSearchElement.addEventListener('focusin', () => {
      headerSearchElement.classList.add('search--expanded');
    });
  }

  if (mobileMenu && mobileSearchTrigger && mobileSearchBar)  {
    mobileSearchTrigger.addEventListener('click', () => {
      if (mobileSearchBar.classList.contains('visually-hidden')) {
        mobileSearchBar.classList.remove('visually-hidden');
        mobileSearchTrigger.classList.add('active');
        $searchInputs.forEach(input => input.focus());
      } else {
        mobileSearchBar.classList.add('visually-hidden');
        mobileSearchTrigger.classList.remove('active');
      }
    })
  }

  // preselect search input on desktop
  headerSearchElement.addEventListener('click', () => {
    const desktopSearchTrigger = document.querySelector('.header--search-bar');
    if (!desktopSearchTrigger.classList.contains('active')) {
      $searchInputs.forEach(input => input.focus());
    }
  });

  let checkScroll = () => {
    if (window.scrollY > 0) {
      header.classList.add('scrolled');
      headerMain.classList.add('scrolled');
      headerSearchElement.classList.remove('search--expanded');
      HeaderHeight()
    } else {
      headerMain.classList.remove('scrolled');
      header.classList.remove('scrolled');
      HeaderHeight()
    }
  }
  
  window.addEventListener('scroll', Throttle(checkScroll, 1));
}
