import { removeLineItem } from "../_modules/_cart";

export const Remove = () => {
    const removeButtons = document.querySelectorAll('[data-remove]');
    removeButtons.forEach((removeButton) => {
        removeButton.addEventListener("click", () => {
            let id = removeButton.getAttribute('data-remove');
            removeLineItem(id)
            const cart = document.querySelector('[data-template="cart"]');
            const cartItems = cart.querySelectorAll('.cart-item:not(.visually-hidden)');
            let showWhenEmpty = cart.querySelectorAll('[data-show-when-empty]');
            let hideWhenEmpty = cart.querySelectorAll('[data-hide-when-empty]');
            if(cartItems.length == 1) {
                hideWhenEmpty.forEach((item) => {
                    item.classList.add('visually-hidden')
                })
                showWhenEmpty.forEach((item) => {
                    item.classList.remove('visually-hidden')
                })
            } 
        })
    })
}