export const HeaderTop = () => {
  const headerTop = document.getElementById('header-top');

  const announcementSlider = document.querySelector('.announcement-bar--swiper');
  
  if (announcementSlider) {
    const $swiperInit = new Swiper(announcementSlider, {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: `.next-button`,
        prevEl: `.prev-button`,
      },
      autoplay: {
        delay: 4000,
      },
    });
  }
}
