export const FeaturedCollections = (section) => {
    const filters = section.querySelectorAll('.featured-collection--filter');
    const galleries = section.querySelectorAll('.collection-swiper-container');
    filters && galleries ?
        filters.forEach((filter) => {
            filter.addEventListener("click", (e) => {
                let filter_id = e.target.getAttribute('data-show-gallery');
                filters.forEach((filter) => {
                    filter.classList.remove('active')
                })
                e.target.classList.add('active')
                galleries.forEach((gallery) => {
                    gallery.getAttribute('data-gallery-index') == filter_id ?
                        gallery.classList.add('active') : gallery.classList.remove('active')
                })
            })
        })
    : null
}