export const nm8 = (section) => {
    if(section.querySelectorAll('.animate-container') != undefined) {
        section.querySelectorAll('.animate-container').forEach((container, i) => {
            container.querySelectorAll('.nm8-item').forEach((item, j) => {
                setTimeout(() => {
                    item.classList.add('loaded')
                }, 100 * j);
            })
        })
    }
}