export const Session = () => {
    const elementsToToggle = document.querySelectorAll('[data-get-session]');
    for (var i = 0; i < sessionStorage.length; i++){
        elementsToToggle.forEach((element) => {
            element.getAttribute('data-get-session') == sessionStorage.key(i) ?
                element.classList.add('visually-hidden') : element.classList.remove('visually-hidden')
        })
    }

    document.addEventListener("click", (e) => {
        if(!e.target.hasAttribute('data-set-session')) { return; }

        let sessionName = e.target.getAttribute('data-set-session');

        let data = sessionStorage.getItem(sessionName);

        if(!data) {
            sessionStorage.setItem(sessionName, true);
        } 
    })
}