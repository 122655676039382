export const VariantSelector = () => {
    const productPrice = document.getElementById('product-price');
    // ==================================================*
    // ===* VARIANT SELECTOR ===*
    // ==================================================*
    // Listener is set to document to allow for dynamically generated elements such as quick view + 
    // Recomended product sections.
    document.addEventListener("change", (e) => {
        let form = e.target.form;
        if(e.target.getAttribute('name') != "id") { return; }
        let inv = e.target.getAttribute('data-inventory');
        let quantity = form.elements['quantity'];
        quantity.setAttribute('max', inv);
        // Reset quantity seelctor value to avoid inventory conflicts
        quantity.value = 1;
        quantity.parentNode.removeAttribute('data-tooltip');

        if(!form.parentNode.classList.contains('product--quick-view-form')) { return; }
            let button = document.querySelector('.product--quick-view-form button[type="submit"]');
            button.removeAttribute('disabled');
            button.innerHTML = 'ADD TO CART';
    })

    const updateMasterSelector = () => {
        console.log('old code')
        const masterSelector = document.getElementById('master-selector');
        if(!masterSelector) { return; }
        const form = masterSelector.form;
        let addToCartButton = form.querySelector('[data-add-to-cart]');
        const quantity = form.elements['quantity'];
        const size = form.elements['size'];
        let barcode = form.elements['properties[barcode]'];
        // Reset quantity selector on vvariant change
        quantity.value = 1;
        form.querySelector('.quantity-label').innerHTML = 1;
        
        masterSelector.querySelectorAll('option').forEach((option, i) => {
            addToCartButton.removeAttribute('disabled');
            let s = option.getAttribute('data-js-size');
            let b = option.getAttribute('data-js-attr-barcode');
            let p = option.getAttribute('data-js-variant-price');
    
            const updateBarcode = () => { 
                if(!b) { return; }
                barcode.value = b;
               // console.log(barcode.value);
            }

            if (size.value == s) {
                masterSelector.selectedIndex = i;
                quantity.setAttribute('max', option.getAttribute('data-inventory'))
                quantity.value = 1;
                if(productPrice) {
                    productPrice.innerHTML = p;
                }
                updateBarcode()
                masterSelector.selectedIndex = i;
            } 
        })
    }
    
    document.addEventListener("change", (e) => {
        if(e.target.hasAttribute('data-js-variant')) {
            updateMasterSelector()
        }
    });

    if (globalThis.Shopify.country === 'AU') {
        document.querySelectorAll('custom-dual-selects').forEach((selector) => { 
            selector.updateHiddenVariantInput(0);
        });
    }
}