export const SwatchGenerator = () => {
    const swatchContainers = document.querySelectorAll('[data-swatches-json]');
    // console.log(swatchJSON);
    if(!swatchContainers) { return; }

    const renderSwatch = (swatchData) => {
        return `
            <a 
            href="/products/${swatchData.handle}"
            class="no-underline swatch small" 
            style="--swatch-color: ${swatchData.hex};">
                <span>
                </span>
            </a>
        `
    }
    swatchContainers.forEach((e) => {
        let temp_swatches=[];

        let swatch_data = JSON.parse(e.getAttribute('data-swatches-json'));
        swatch_data.map((swatch, i) => {
            let handle = swatch.variant_colour_swatch.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase();
            let result = swatchJSON.find(({ name }) => name === handle);
            if(result) {
                temp_swatches.push({
                    "hex": result.hex,
                    "handle": swatch.variant_handle,
                    "swatch": swatch.variant_colour_swatch
                })
            }
        })
        temp_swatches.map((swatch, i) => {
            i < 5 ?
                e.innerHTML += renderSwatch(swatch) : null
        })
        if(temp_swatches.length > 5) {
            e.innerHTML += `<div class="text--grey tiny">+${swatch_data.length - 5}</div>`
        }
    })
}