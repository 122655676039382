export const SearchPage = () => {
    const search_filters = document.querySelectorAll('.search--filters button');
    const search_results = document.querySelectorAll('.search--results');
    search_filters.forEach((filter) => {
        filter.addEventListener("click", (e) => {
            let id = e.target.getAttribute('data-show');
            search_results.forEach((result) => {
                result.classList.remove('active')
            })
            search_filters.forEach((filter) => {
                filter.classList.remove('active')
            })
            document.querySelector(id).classList.add('active')
            e.target.classList.add('active')
        })
    });
}