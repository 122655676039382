// ==================================================*
// ===* CART VARIABLES ===*
// ==================================================*
var ShopifyCart = [];
const cart__items = document.querySelector('.cart-items');
const discount = document.getElementById('discount');
const cart__total = document.getElementById('total');
// const gwp = document.getElementById('gwp');
// const gwpID = Number(gwp.getAttribute('gwp-variant-id'));

import { currency, currencyWithoutSymbol } from '../_utilities/_currency';
// ==================================================*
// ===* CART API ===*
// ==================================================*

// ===* GET CURRENT CART OBJECT ===*
export const  getCartContents = () => fetch("/cart.js")
    .then(response => response.json())

// ===* UPDATE CART OBJECT & UPDATE DOM ===*
export const ShopifyCartUpdate = (id, cancel_events) => {
    getCartContents().then(data => {
        ShopifyCart = data;
        updateDOM(ShopifyCart, id, cancel_events);
    })
}
// ===* ADD CART ITEM ===*
export const Add = (quantity, id, barcode, productType) => {
    let formData;

    if (productType === 'gwp') {
        formData = {
            'items': [{
                'id': id,
                'quantity': quantity,
                'properties': {
                    "barcode": '',
                    "_gwp": 'false'
                }
            }]
        };
    } else if(barcode != null) {
       // console.log('adding with barcode', barcode);
        formData = {
            'items': [{
            'id': id,
            'quantity': quantity,
            'properties': {
                "barcode": barcode
            }
            }]
        };
    } else {
        formData = {
            'items': [{
                'id': id,
                'quantity': quantity,
            }]
        };
    }

    fetch(window.Shopify.routes.root + 'cart/add.js', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    .then(response => {
        return response.json();
    })
    .then(() => ShopifyCartUpdate(id))
    .catch((error) => {
        console.error('Error:', error);
    });
}

// ===* UPDATE CART ITEM ===*
export const updateLineItem = (quantity, id) => {
    let formData = {
        'updates': {
            [id]: quantity
        }        
    };

    fetch('/cart/update.js', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    .then(() => ShopifyCartUpdate())
    .catch((error) => {
        console.error('Error:', error);
    });
}
// ===* REMOVE CART ITEM ===*
export const removeLineItem = (id) => {
    let formData = {
        'updates': {
            [id] : 0
        }
    };
    fetch('/cart/update.js', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((data) => updateNextItem(data, id))
    .catch((error) => {
        console.error('Error:', error);
    });

    const updateNextItem = (data, id) => {
        let cart_item = document.querySelectorAll(`.cart-item[data-variant-id="${id}"]`);
        cart_item.forEach((item) => {
            // item.classList.add("visually-hidden");
        })
        ShopifyCartUpdate()
        const newFirstLineItem = document.querySelector('.cart-item:not(.visually-hidden)');
       // console.log(newFirstLineItem.getAttribute('data-barcode'));
        let currentQuantity = newFirstLineItem.querySelector('.vue--quantity-selector').querySelector('input').value;
        if(data.items) {
            var data = JSON.stringify({
                'line': 1,
                'quantity': currentQuantity,
                'properties': {
                    '_cards': [{
                        '_code': sessionStorage.getItem('giftCards'),
                        '_balance': sessionStorage.getItem('discount'),
                        '_signature': sessionStorage.getItem('signature'),
                        '_currency': sessionStorage.getItem('currency')
                    }],
                    'barcode': newFirstLineItem.getAttribute('data-barcode')
                }
            });
            fetch('/cart/change.js', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data
            })
            .then((response) => response.json())
            .then((data) => console.log(data))
        }

    }
}

// ==================================================*
// ===* UPDATE THE DOM ===*
// ==================================================*
export const updateDOM = (cart, id, cancel_events) => {
    // ===* CART TOTAL (INDICATOR ON ICON) ===*
    const cart__totals = document.querySelectorAll('.cart--total');
    const progress_bar = document.querySelector('.progress-bar');
    const progress_text = document.getElementById('promotion-message');
    const remaining = document.getElementById('remaining');
    const cart_items = document.querySelector('.cart-items');

    // CART INDICATOR ICON ANIMATION
    let animateIndicators = true;
    // MANUALLY CANCEL ANIMATION FUNCTION
    if(cancel_events) {
        cancel_events.cancel.map((cancel) => {
            if(cancel == 'indicatorAnimation') {
                animateIndicators = false;
            }
        })
    }


    if(animateIndicators) {
        cart__totals.forEach((total) => {
            total.classList.add('transition--shrink-pop');
            setTimeout(() => {
                total.classList.remove('transition--shrink-pop');
                total.innerText = cart.item_count;
            }, 500);
        });
    }

    // ===* CART EMPTY STATE ===*
    const cartEmpty = () => {
       // console.log('cart empty states to be designed');
    }

    const renderNewLineItem = (item) => {
        let options = ''
        for(var i=0; i < 10; i++) {
            options += `
            <li class="pd-x--s pd-y--xs" data-value="${i}" data-toggle="[data-id='${item.variant_id}--quantity-inner']">
            ${i}
            </li>
            `
        }
        if(!cart__items) { return; }
        cart__items.innerHTML += `
        <div class="cart-item mini-cart-item cart-item-grid" data-variant-id="${item.variant_id}">
            <a href="${item.url}" class="no-underline">
                <div class="image-container ratio-1-1 image-bleed-none cover mg-right--s">
                    <img src="${item.featured_image.url}" class="lazy content entered loaded" />
                </div>
            </a>
            <p class="title">${item.title}</p>              
                <div class="quantity-selector border-grey relative h-fit w-fit" data-cart-selector="true" data-variant-id="${item.variant_id}">
                    <input type="hidden" value="2" name="quantity" max="">
                    <button type="button" class="flex flex-r flex-middle gap--xs pd-x--s pd-y--xs relative" data-toggle="[data-id='${item.variant_id}--quantity-inner']">
                        <span class="quantity-label">2</span>
                        <svg class="absolute" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L11 1" stroke="black"></path>
                        </svg>
                    </button>
                    <ul class="quantity-selector--inner border-grey w-100 absolute flex-column" data-id="${item.variant_id}--quantity-inner">
                    ${options}        
                    </ul>
                </div>
                <p class="cart-item--total">${currency(item.original_price)}</p>
                <div class="no-underline text--center h-fit w-fit m-0-auto mg--0-auto" data-remove="${item.variant_id}">
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2499 3.85312H8.99994V3.12083C8.99994 1.90746 7.99259 0.923828 6.74995 0.923828H5.25005C4.00741 0.923828 3.00006 1.90746 3.00006 3.12083V3.85312H0.75008C0.335858 3.85312 0 4.18107 0 4.58554C0 4.99 0.335858 5.31783 0.75008 5.31783H1.50003V11.1766C1.50003 12.39 2.50738 13.3736 3.75002 13.3736H8.24998C9.49262 13.3736 10.5 12.39 10.5 11.1766V5.31783H11.2499C11.6641 5.31783 12 4.99 12 4.58554C12 4.18107 11.6641 3.85312 11.2499 3.85312ZM4.49997 3.12083C4.49997 2.71636 4.83583 2.38854 5.25005 2.38854H6.74995C7.16417 2.38854 7.50003 2.71636 7.50003 3.12083V3.85312H4.49997V3.12083ZM8.99994 11.1766C8.99994 11.5811 8.6642 11.9089 8.24998 11.9089H3.75002C3.33579 11.9089 3.00006 11.5811 3.00006 11.1766V5.31783H8.99981L8.99994 11.1766Z" fill="black"></path>
                    </svg> 
                    <span class="visually-hidden">REMOVE</span>                               
                </div>
            </div>
        `
    }

    // ===* CART LINE ITEMS ===*
    // cart.items.length == 0 ? cartEmpty() : null
    // cart.items.map((item) => {
    //     const itemInCart = document.querySelector(`.cart-item[data-variant-id="${item.variant_id}"]`);
    //     if(itemInCart) {
    //         // ------ Line Item Price
    //         let line_item_price = itemInCart.querySelector('.cart-item--total');
    //         let line_item_quantity = itemInCart.querySelector('.quantity-label');
    //         line_item_price ? line_item_price.innerHTML = currency(item.line_price) : null
    //         line_item_quantity ? line_item_quantity.innerHTML = item.quantity : null
    //     } else {
    //         renderNewLineItem(item)
    //     }
    // })

    // // ===* CART SUBTOTAL ===*
    // const cart__subtotal = document.getElementById('subtotal')
    //     cart__subtotal ? cart__subtotal.innerText = currency(cart.items_subtotal_price) : null
    // const cart__subtotal_discount = document.querySelector('.cart--subtotal-discount span');
    //     cart__subtotal_discount ? cart__subtotal_discount.innerText = currency(cart.total_discount) : null

    //     if(discount.getAttribute('data-discount') > 0) {
    //         if(discount.getAttribute('data-discount') >= cart.total_price) {
    //             cart__total.innerText = currency(0)
    //         } else {
    //             cart__total.innerText = currency(cart.total_price - discount.getAttribute('data-discount'));
    //         }
    //     } else {
    //         cart__total.innerText = currency(cart.total_price);
    //     }

    // const afterpay_four = document.getElementById('afterpay-four');
    // const afterpay_six = document.getElementById('afterpay-six');

    // afterpay_six ? afterpay_six.innerHTML = currency((cart.total_price - discount.getAttribute('data-discount')) / 6) : null
    // afterpay_four ? afterpay_four.innerHTML = currency((cart.total_price - discount.getAttribute('data-discount')) / 4) : null

    // ===* PRODUCT CARD ===*
    const product_cards = document.querySelectorAll('.product-card');
    product_cards.forEach((productCard) => {
        cart.items.map((item) => {
            item.product_id == productCard.getAttribute('data-product-id') ?
            productCard.querySelector('button').innerHTML = 'Added' : null
        });
    })
}

// ==================================================*
// ===* GWP at Mini cart ===*
// ==================================================*
// if (gwp.getAttribute('data-gwp') === 'minicart') {
//     const gwpPrice = gwp.getAttribute('gwp-price');
//     const gwpAddToCart = gwp.querySelector('[data-add-to-cart]');
//     const gwpToggle = document.querySelector('.gwp-toggle');
//     const gwpContainer = document.querySelector('.gwp-container');
//     const gwpClose = document.querySelector('.gwp-content .close');
//     let total_gwp_price = 0;

//     cartItem();

//     gwpToggle.addEventListener('click', () => {
//         gwpContainer.classList.add('active');
//     })

//     gwpClose.addEventListener('click', () => {
//         gwpContainer.classList.remove('active');
//     })

//     gwpAddToCart.addEventListener('click', () => {
//         const barcode = gwp.querySelector('form').getAttribute('gwp-barcode');
//         Add(1, gwpID, Number(barcode), 'gwp');
//         setTimeout(() => { cartItem(); }, 1500);
//     })

//     const cartItem = () => {
//         const itemDiv = document.querySelectorAll('.go-cart-item__single');

//         itemDiv.forEach((div) => {
//             const divInfo = div.querySelector('.go-cart-item__info');
//             const itemId = divInfo.querySelector('a').getAttribute('href').split('variant=')[1];
//             if (Number(itemId) === gwpID) {
//                 div.classList.add('gwp-item');
//                 div.setAttribute('data-gwp-id', gwpID)
//                 gwpContainer.classList.add('hide');
//             }
//         });

//         getCartContents().then(data => {
//             const gwpItem = document.querySelector(`.go-cart-item__single[data-gwp-id="${gwpID}]`);
//             data.items.forEach((item) => {
//                 if (item.properties._gwp === "true") {
//                     if (item.original_line_price > item.final_line_price) {
//                         total_gwp_price += item.final_line_price
//                     } else {
//                         total_gwp_price += item.original_line_price
//                     }
//                 }
//                 if (item.id === gwpID) {
//                     gwpItem.classList.add('gwp-item');
//                     gwpContainer.classList.add('hide');
            
//                     if (total_gwp_price !== 0 && total_gwp_price < Number(gwpPrice)) {
//                         gwpContainer.classList.add('hide');
//                     } 
//                 }   
//             })

//             if (total_gwp_price !== 0 && total_gwp_price > Number(gwpPrice)) {
//                 gwpContainer.classList.remove('hide');
//             }
//         })
//     };
// }
