export const AnchorLinks = () => {
    const sections = document.querySelectorAll('section');

    const scrollToSection = (sectionIndex) => {
        let sectionId = Number(sectionIndex) - 1;
       // console.log(sections);
        var bodyRect = document.body.getBoundingClientRect(),
        elemRect = sections[sectionId].getBoundingClientRect(),
        headerHeight = 103,
        niceSpacingForNiceness = 20,
        offset = elemRect.top - bodyRect.top - headerHeight - niceSpacingForNiceness;

        window.scrollTo({
            top: offset,
            left: 0,
            behavior: 'smooth'
        });
    }

    document.addEventListener("click", (e) => {
        if(!e.target.hasAttribute('data-anchor-id')) { return; }
        scrollToSection(e.target.getAttribute('data-anchor-id'));
    })
}