import {updateLineItem } from './_cart'

export const QuantitySelector = () => {
    // ==================================================*
    // ===* QUANTITY SELECTOR ===*
    // ==================================================*
    const quantitySelectors = document.querySelectorAll('.quantity-selector');
    if(!quantitySelectors) { return; }

    quantitySelectors.forEach((quantitySelector) => {
        const inner = quantitySelector.querySelector('.quantity-selector--inner');
        const quantityOptions = inner.querySelectorAll('[data-value]');
        const quantityLabel = quantitySelector.querySelector('.quantity-label');
        const hiddenInput = quantitySelector.querySelector('input[name="quantity"]');
        let isCartSelector = quantitySelector.getAttribute('data-cart-selector');

        quantityOptions.forEach((option, i) => {
            option.addEventListener("click", (e) => {
                let quantity = e.target.getAttribute('data-value');
               // console.log(quantity);
                quantityLabel.innerHTML = quantity;
                hiddenInput.value = quantity;
                isCartSelector == "true" ? updateLineItem(quantity, quantitySelector.getAttribute('data-variant-id')) : null
            })
        })
    })

}