export const Carousel = (carousels) => {
    if(!carousels) { return; }
    carousels.forEach((carousel) => {
        let activeSlide = parseInt(carousel.querySelector('.slide.active').getAttribute('data-slide-number'));
        const slides = carousel.querySelectorAll('.slide');
        const paginationItems = carousel.querySelectorAll('.carousel--pagination-item');
        let slideCount = slides.length;
        let nextSlideButton = carousel.querySelector('.next-slide');
        let prevSlideButton = carousel.querySelector('.prev-slide');
        
        if(prevSlideButton) {
            prevSlideButton.addEventListener("click", () => {
                activeSlide > 0 ?
                    updateSlide(activeSlide-1) : updateSlide(0)
            })
        }
        if(nextSlideButton) {
            nextSlideButton.addEventListener("click", () => {
                activeSlide <= slideCount ? 
                    updateSlide(activeSlide+1) : null
            })
        }

        let updateSlide = (newSlideNumber) => {
            slides.forEach(( slide, i ) => {
                i == newSlideNumber ?
                    slide.classList.add('active') : slide.classList.remove('active')
            })
            paginationItems.forEach(( item, i ) => {
                i == newSlideNumber ?
                    item.classList.add('active') : item.classList.remove('active')
            })
        }
    });
}