import { DeviceIsMobile } from "../_utilities/_windowSizeDetection"

export const Cycle = () => {

    const cycleElements = document.querySelectorAll('[data-m-cycle]');
    if(!DeviceIsMobile || !cycleElements) { return }

    const showElement = (elements) => {
        let elementsCount = elements.length - 1;
        setInterval(function(){
            let activeIndex = document.querySelector('[data-m-cycle-item].active');
            let activeNo = Number(activeIndex.getAttribute('data-m-cycle-item'));
            if(activeNo < elementsCount) {
                activeIndex.classList.remove('active');
                elements[activeNo + 1].classList.add('active')
            } else {
                activeIndex.classList.remove('active');
                elements[0].classList.add('active')
            }
        }, 4000)
    }
    
    cycleElements.forEach((cycle) => {
        let cycleElements = cycle.querySelectorAll('[data-m-cycle-item]');
        showElement(cycleElements);
    })
}