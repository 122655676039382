import { Add, removeLineItem } from "./_cart";

export const GiftBox = () => {
    // ---* SESSION ---* 
    const giftBoxInCart = sessionStorage.getItem('giftBoxInCart');
    const from = sessionStorage.getItem('giftBoxFrom');
    const to = sessionStorage.getItem('giftBoxTo');
    const message = sessionStorage.getItem('giftBoxMessage');
    // ---* ELEMENTS ---* 
    const orderNotes = document.getElementById('orderNotes');
    const giftBoxTo = document.getElementById('giftBoxTo');
    const giftBoxFrom = document.getElementById('giftBoxFrom');
    const giftBoxMessage = document.getElementById('giftBoxMessage');
    const giftBoxProduct = document.getElementById('giftBoxProduct');
    const giftBoxCheckbox = document.getElementById('giftBoxCheckbox');
    const giftBoxUi = document.querySelector('.gift-box--ui');

    if(!giftBoxProduct) { return; }
    const giftBoxProductBarcode=giftBoxProduct.getAttribute('data-barcode');
    // console.log(giftBoxProductBarcode);
    
    if(giftBoxInCart == 'true') {
        giftBoxCheckbox.checked = true;
        giftBoxUi.classList.add('active')
    }

    if(from != null) {
        giftBoxFrom.value = from;
    }

    if(to != null) {
        giftBoxTo.value = to;
    }

    if(message != null) {
        giftBoxMessage.value = message;
    }


    if(
        !giftBoxTo || 
        !giftBoxFrom || 
        !giftBoxMessage || 
        !orderNotes || 
        !giftBoxCheckbox) 
    { return; }
    
    const updateOrderNote = (field) => {
        field == 'to' ?
            sessionStorage.setItem('giftBoxTo', giftBoxTo.value) :
        field == 'from' ?
            sessionStorage.setItem('giftBoxFrom', giftBoxFrom.value) :
        field == 'message' ?
            sessionStorage.setItem('giftBoxMessage', giftBoxMessage.value) :
        null
        // TODO: find solution for indenting with template literals.
        orderNotes.value=`From: ${giftBoxFrom.value} 
To: ${giftBoxTo.value}
Message: ${giftBoxMessage.value}`;
    }

    const toggleGiftBoxToCart = () => {
        if(sessionStorage.getItem('giftBoxInCart') != 'true') {
            sessionStorage.setItem("giftBoxInCart", "true");
            Add(1, parseInt(giftBoxProduct.value),giftBoxProductBarcode);
           // console.log('add');
        } else {
            removeLineItem(giftBoxProduct.value);
           // console.log('remove');
            sessionStorage.removeItem('giftBoxInCart');

        }
    }

    giftBoxTo.addEventListener('keyup', () => updateOrderNote('to'));
    giftBoxFrom.addEventListener('keyup', () => updateOrderNote('from'));
    giftBoxMessage.addEventListener('keyup', () => updateOrderNote('message'));
    giftBoxCheckbox.addEventListener('change', () => toggleGiftBoxToCart());
}