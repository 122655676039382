import { DeviceIsMobile } from "../_utilities/_windowSizeDetection";

export const VariantSelectorSizes = () => {
  // This renders the custom select options on the PDP. Reference /snippets/product-form--simple
  // Get the master selector from the form.

  // NOTE: This is also used for the complimentary products: snippets/product-form--quick-add.liquid

  const options = {
    name: "variantSelectorSizes",

    el: "#variantSelectorSizes",

    data() {
      return {
        mounted: false,
        cupSizes: [],
        bandSizes: [],
        singleSizes: [],
        renderedCupSizes: [],
        customerCup: "",
        customerBand: "",
        locations: [],
        inventoryDC: [],
        loading: true
      };
    },

    mounted() {
      const atcButton = this.$refs.masterSelector.form.querySelector(
        "button[data-add-to-cart]"
      );
      this.grabInventoryByLocation();
      this.mounted = true;
      this.buildDropdowns();
      atcButton.innerText = `Select ${atcButton.dataset.variantType}`;
      //   this.disableMiniCartOnMobile(atcButton);
      this.setDefaultBySession();
      this.customerBand = sessionStorage.getItem("customer-band");
      this.customerCup = sessionStorage.getItem("customer-cup");
    },
    
    methods: {
      async grabInventoryByLocation(id) {
        let searchId = this.$refs.masterSelector.form.dataset.productid;
        if (id) {
            searchId = id;
        }
        async function fetchInventory() {
            const response = await fetch(`/a/ps/shopify/product/${searchId}`);
            const data = await response.json();
            return data;
        }
        fetchInventory().then(data => {
            this.locations = data.data.locations;
            this.locations.forEach((location) => {
                if (location.name == 'DC' || location.name == 'NZ DC') {
                  this.inventoryDC = location.inventories;
                }
            })
          this.loading = false;
          this.buildDropdowns();
        });
    },
      async disableMiniCartOnMobile(atcButton) {
        await atcButton;
        if (DeviceIsMobile() && atcButton) {
          atcButton.classList.remove("js-go-cart-add-to-cart");
          atcButton.setAttribute("type", "submit");
        }
      },
      async setDefaultBySession() {
        await this.$refs.bandSelector;
        await this.$refs.cupSelector;
        let form = this.$refs.masterSelector.form;

        this.$refs.masterSelector
          .querySelectorAll("option")
          .forEach((option, i) => {
            let inv = Number(option.dataset.inventory);

            if (inv > 0) {
              if (
                option.dataset.title.includes(this.customerBand) &&
                !option.dataset.title.includes(this.customerCup)
              ) {
                // Matches band only
                this.setBandSelector();
                this.$refs.masterSelector.selectedIndex = i;
                return;
              } else if (
                option.dataset.title.includes(this.customerBand) &&
                option.dataset.title.includes(this.customerCup)
              ) {
                // Matches both
                this.setBandSelector();
                this.setCupSelector();
                this.$refs.masterSelector.selectedIndex = i;
                return;
              } else if (
                !option.dataset.title.includes(this.customerBand) &&
                option.dataset.title.includes(this.customerCup)
              ) {
                // Matches cup only
                this.setCupSelector();
                this.$refs.masterSelector.selectedIndex = i;
                return;
              }
            }
          });
      },
      setBandSelector() {
        this.$refs.bandSelector
          .querySelectorAll("option")
          .forEach((option, j) => {
            if (option.value == this.customerBand) {
              this.$refs.bandSelector.selectedIndex = j;
            }
          });
        this.selectBand();
      },
      setCupSelector() {
        this.$refs.cupSelector
          .querySelectorAll("option")
          .forEach((option, j) => {
            if (option.value == this.customerCup) {
              this.$refs.cupSelector.selectedIndex = j;
            }
          });
        this.selectBand();
      },

      async buildDropdowns() {
        // Loop through all options of the master selector
        this.$refs.masterSelector
          .querySelectorAll("option")
          .forEach((option) => {
            let variantTitle = option.dataset.title;
            let inventoryManagement = option.dataset.inventoryManagement;
            // Because the cup + band size are bundeled into one variant - we need to manually split these options
            if (!variantTitle || !inventoryManagement) {
              return;
            }
            if (variantTitle.includes("/")) {
              let bandSize = variantTitle.split(" ")[0];
              let cupSize = variantTitle.split(" ")[1];
              // Push the band/cup sizes to their respective arrays
              !this.cupSizes.includes(cupSize)
                ? this.cupSizes.push(cupSize)
                : null;

              !this.bandSizes.includes(bandSize)
                ? this.bandSizes.push(bandSize)
                : null;
            } else {
              if (this.inventoryDC[option.value] == 'Out of Stock' && option.dataset.available == 'true') {
                option.innerText = option.dataset.title + ' - In Store Only'
              }
            }
          });
        if (this.cupSizes.length <= 0) {
          return;
        }
        // Initial select to build cup options
        this.selectBand();
      },

      async selectBand() {
        // Only run once select is rendered
        await this.$refs.bandSelector;
        //await this.$refs.cupSelector;

        const activeBand = this.$refs.bandSelector.value ?? null;
        let cupOptions = [];
        this.$refs.masterSelector
          .querySelectorAll("option")
          .forEach((option) => {
            option.dataset.title.includes(activeBand)
              ? cupOptions.push(option)
              : null;
          });
        // reset the rendered cupSelector values
        this.renderedCupSizes = [];

        // Build the cupOptions object for cupSelector
        cupOptions.map((cupOption, i) => {
          this.cupSizes.map((cupSize, j) => {
            // Add space in check to ensure D isn't confused for DD
            if (cupOption.dataset.title.includes(` ${cupSize} `)) {
              this.renderedCupSizes.push({
                title: cupSize,
                inventory: cupOption.dataset.inventory,
                store: this.inventoryDC[cupOption.value] == 'In Stock' || this.inventoryDC[cupOption.value] == 'Low Stock'
              });
            }
          });
        });
       
        // Find first cup with inventory > 0
        const i = this.renderedCupSizes.findIndex((e) => {
          return e.inventory > 0;
        });

        let matchedSession = false;

        this.renderedCupSizes.map((option, j) => {
          if (!this.customerCup) {
            return;
          }
          if (this.customerCup.includes(option.title) && option.inventory > 0) {
            matchedSession = true;
            this.$refs.cupSelector.selectedIndex = j;
            //console.log(option, j);
          }
        });

        if (!matchedSession) {
          // If option with inventory > 0 exists assign as active
          i > -1 ? this.$refs.cupSelector.selectedIndex = i : null;
        }

        this.updateMaster();
      },
      async updateMaster() {
        // Only run once select is rendered
        await this.$refs.cupSelector;

        let activeCup = this.$refs.cupSelector.value;

        setTimeout(() => {
          if (sessionStorage.getItem("customer-cup")) {
            activeCup = sessionStorage.getItem("customer-cup");
            this.customerCup = activeCup;
          }

          let activeBand = this.$refs.bandSelector.value;
          // Loop through master and find option with matching cup + band
          this.$refs.masterSelector
            .querySelectorAll("option")
            .forEach((option, i) => {
              if (
                option.dataset.title.includes(` ${activeCup}`) &&
                option.dataset.title.includes(activeBand)
              ) {
                // Update masterSelector to matching option
                this.$nextTick(() => {
                  this.$refs.masterSelector.selectedIndex = i;
                  this.updateAtcButton(option);
                  return;
                });
                //this.updateCupSelector(activeBand);
              }
            });
        }, 10);
      },
      updateCupSelector(activeBand) {
        this.$refs.masterSelector
          .querySelectorAll("option")
          .forEach((option, i) => {
            if (option.dataset.title.includes(activeBand)) {
              this.cupSizes.map((cupSize, j) => {
                // Add space in check to ensure D isn't confused for DD
                if (option.dataset.title.includes(` ${cupSize} `)) {
                  if (option.dataset.inventory <= 0) {
                    this.$refs.cupSelector.options[j].setAttribute(
                      "disabled",
                      true
                    );
                  } else {
                    this.$refs.cupSelector.options[j].removeAttribute(
                      "disabled"
                    );
                  }
                }
              });
            }
          });
      },
      updateAtcButton(option) {
        const atcButton = this.$refs.masterSelector.form.querySelector(
          "button[data-add-to-cart]"
        );

        let inventoryManagement = option.dataset.inventoryManagement;

        // If there is no inventoryDC option for it (either in stock or out of stock) or the variant is completely out of stock then set button back to original state
        if (option.dataset.available == "false") { 
          atcButton.innerText = `Select ${atcButton.dataset.variantType}`;
          atcButton.setAttribute("disabled", true);
          return;
        }
          // If option is in stock update ATC button
          if (!inventoryManagement || this.loading == true || (option.dataset.available == "true" && (this.inventoryDC[option.value] == 'In Stock' || this.inventoryDC[option.value] == 'Low Stock'))) {
            atcButton.removeAttribute("disabled");
            atcButton.removeAttribute("data-toggle");
            atcButton.innerText = "Add to Cart";
            atcButton.classList.add("js-go-cart-add-to-cart");
            atcButton.classList.remove('add-to-cart--check-in-store');
          } else {
            atcButton.removeAttribute("disabled");
            atcButton.innerText = ``;
            atcButton.setAttribute("data-toggle", ".check-in-store--popup");
            atcButton.classList.remove("js-go-cart-add-to-cart");
            atcButton.classList.add('add-to-cart--check-in-store');
          }
      },
    },
  };

  const productForms = document.querySelectorAll(".product-form");

  productForms.forEach((form) => {
    Vue.createApp(options).mount(form);
  });

  document.addEventListener("change", (e) => {
    if (e.target.classList.contains("band-selector")) {
      sessionStorage.setItem("customer-band", e.target.value);
      const customerCup = document.querySelector('.cup-selector');
      if (customerCup.value && customerCup.value.trim() != '') {
        sessionStorage.setItem("customer-cup", customerCup.value);
      } else {
        sessionStorage.setItem("customer-cup", customerCup.options[0].value);
      }
    }
    e.target.classList.contains("cup-selector")
      ? sessionStorage.setItem("customer-cup", e.target.value)
      : null;
  });
};
