import '../_modules/_lazyloading';
import { Throttle } from '../_utilities/_throttle';
import { Session } from '../_modules/_session'
import { QuantitySelector } from '../_modules/_quantitySelector'
import { VariantSelector } from '../_modules/_variantSelector'
import { VariantSelectorSizes } from'../_modules/_variantSelectorSizes'
import { Remove } from '../_cart/_remove'
import { SubmitForm } from '../_modules/_submitForm'

import { nm8 } from '../_modules/_nm8'
import { Carousel } from '../_modules/_carousel'
import { FeaturedCollections } from '../_modules/_featuredCollections'
import { SearchPage } from '../_modules/_searchPage'
import { HeaderHover } from '../_modules/_headerHover'
import { HeaderHeight } from '../_modules/_headerHeight'
import { ShopifyCartUpdate } from '../_modules/_cart'
import { AnchorLinks } from '../_modules/_anchorLinks'
import { HeaderTop } from '../_modules/_headerTop'
import { Header } from '../_modules/_header'
import { SwatchGenerator } from '../_modules/_swatchGenerator'
import { Swipers } from '../_modules/_swipers'
import { GiftBox } from '../_modules/_giftBox'
import { Cycle } from '../_modules/_cycle'
import { CloseBanner } from '../_modules/_closeBanner'
import { DeviceIsMobile } from '../_utilities/_windowSizeDetection'

document.addEventListener("DOMContentLoaded", () => {
    const body = document.querySelector('body');

    console.log(body);

    // const header = document.getElementById('site-header');
    // body.classList.add('fade-in');
    Session() // Session logic (banner message close)
    HeaderHeight()
    window.addEventListener("beforeunload", () => {
        body.classList.remove('fade-in');
    });
    // ===* URL PARAMS ===*
    // ==================================================*
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const view = urlParams.get('view');
    const productCards = document.querySelectorAll('.product--card');

    if(view == 'model') {
        productCards.forEach((productCard) => {
            let img = productCard.querySelector('img');
            if(img.hasAttribute('data-alt-src')) { 
                img.setAttribute('src', img.getAttribute('data-alt-src'))
            }
            if(img.hasAttribute('data-alt-srcset')) { 
                img.setAttribute('data-srcset', img.getAttribute('data-alt-srcset'));
                img.classList.add('cover')
            }
        });
    } 
    // ==================================================*
    // ===* SECTION ===*
    // ==================================================*
    const SectionInView = (sectionElement) => {
        // Perform lazyloading + section functions if the section is in view
        if (sectionElement.classList.contains('loaded')) {
          return;
        }
        const videos = sectionElement.querySelectorAll('video:not(.pdp-video)');
        const carousels = sectionElement.querySelectorAll('.carousel');
        const featuredCollections = sectionElement.querySelector('.featured-collections--container');
        nm8(sectionElement)
        videos ? VideoLazyLoad(videos) : null
        carousels ? Carousel(carousels) : null
        featuredCollections ? FeaturedCollections(sectionElement) : null
        sectionElement.classList.add('loaded')
    }

    const VideoLazyLoad = (videoElements) => {
      videoElements ?
        videoElements.forEach((video) => {
          let videoSource = video.querySelector('source');
          if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
            console.log('Device listener picks up mobile:', DeviceIsMobile());
            if(DeviceIsMobile() && videoSource.hasAttribute('data-mobile-src')) {
              videoSource.src = videoSource.dataset.mobileSrc;
              video.load()
            } else {
              videoSource.src = videoSource.dataset.src;
              video.load()
            }
          }
        })
      : null
    }

    const toggleSound = (button) => {
      const video = button.previousElementSibling.querySelector('video');

      if (video.muted) {
        video.muted = false;
        button.classList.toggle('sound-off');
      } else {
        video.muted = true;
        button.classList.toggle('sound-off');
      }
    }

    document.querySelectorAll('.sound-toggle').forEach(($button) => {
      $button.addEventListener('click', () => {
        toggleSound($button);
      });
    });

    window.addEventListener('resize', Throttle(() => {
      console.log('Resize event triggered.');
      const videoElements = document.querySelectorAll('video');
      VideoLazyLoad(videoElements);
    }, 200));

    const toggleBodyScroll = document.querySelectorAll('[data-toggle-body-scroll]');

    toggleBodyScroll.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        document.body.classList.toggle('no-scroll');
        const screenSize = window.screen;
        const mobileHeader = document.getElementById('mobile-menu-main');
        mobileHeader.setAttribute('style', `--available-height: ${screenSize.availHeight}px;`);
      });
    });

    // Header height
    document.addEventListener('click', (e) => {
      if (e.target.hasAttribute('data-update-header-height')) {
        HeaderHeight();
      }
      
      e.target.hasAttribute('data-toggle') ? 
        document.querySelector(`${e.target.getAttribute('data-toggle')}`).classList.toggle('active')
        : null;
    });

    // ==================================================*
    // ===* ENTER VIEW ===*
    // ==================================================*
    enterView({
        selector: 'section, footer',
        offset: 0.3,
        enter: (el) => {
            SectionInView(el)
        },
        exit: () => {
            // el, progress
        },
        progress: function() {
            
        }
    });

    // ==================================================*
    // ===* LOAD GLOBAL COMPONENTS ===*
    // ==================================================*
    HeaderTop()
    Header() // DGeneral Header logic TODO: Merge with Header hover as 2 speerate exports
    HeaderHover() // Managing hover state of header menu items (DESKTOP)
    QuantitySelector() // Managing all quantity selectors
    VariantSelector() // Managing all variant selectors
    // AddToCartButton() // Managing ajax add to cart
    SubmitForm() // WIP
    SwatchGenerator()
    Swipers() // All carousels throughout the site
    AnchorLinks()
    // Discounts()
    Cycle()
    // Close top Banner
    CloseBanner()


    // ==================================================*
    // ===* LOAD PAGE SPECIFIC COMPONENTS ===*
    // ==================================================*
    if(document.body.getAttribute('data-page-template') == 'search') {
        SearchPage()
    }
    if(document.body.getAttribute('data-page-template') == 'cart') {
        Remove()
        let cancel_events={cancel: ['indicatorAnimation']};
        ShopifyCartUpdate(null, cancel_events)
        GiftBox()
    }
    if(document.body.getAttribute('data-page-template') == 'product' || document.querySelector('#shopify-section-go-cart')) {
        VariantSelectorSizes();
    }

    // Detect whether goCart is running mutations (TODO: remove after getting rid of goCart)
    const $cartDrawer = document.querySelector('.go-cart__drawer');

    if ($cartDrawer) {
      const cartDrawerCallback = function(mutationsList, observer) {
        console.log('observed');
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if ($cartDrawer.classList.contains('is-open')) {
              // Disable document scrolling
              document.documentElement.style.overflow = 'hidden';
            } else {
              // Enable document scrolling
              document.documentElement.style.overflow = '';
            }
          }
        }
      };

      const observer = new MutationObserver(cartDrawerCallback);
      const config = { attributes: true, attributeFilter: ['class'] };
      observer.observe($cartDrawer, config);
    }
});